import { useParams } from 'react-router-dom';
import Body from '../components/Body';

export default function UserPage() {
  const { userid } = useParams();

  return (
    <Body sidebar>
      <h1>{userid}</h1>
      <p>TODO</p>
    </Body>
  );
}