import { useState, useEffect, useRef } from "react";
import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Button, Modal, TextField } from "@mui/material";
import ActionForm from "./ActionForm";
import { useApi } from "../contexts/IncidentCommandApiProvider";
import { useWebSocket } from '../contexts/WebsocketProvider';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const columns = [
  {
    field: "action_summary",
    headerName: "Actions",
    width: 250,
    headerClassName: "actions-header",
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    headerClassName: "actions-header",
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 120,
    headerClassName: "actions-header",
    renderCell: (params) => (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleRemoveAction(params.row.action_id)}
      >
        Remove
      </Button>
    ),
  },
];

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const handleRemoveAction = (actionId) => {
  // Perform action removal logic based on actionId
  // ...
};


export default function ActionsList({children, actionsData, responder, authUser}) {
  const webSocketService = useWebSocket();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [formContext, setFormContext] = useState("Add");
  //const [serviceId, setServiceId] = useState(incident_service_id);


  
  useEffect(() => {
    if (selectedRowData) {
      setFormContext("Update");
    } else {
      setFormContext("Add");
    }
  }, [selectedRowData]);


  useEffect(() => {
    setActions(actionsData || []); 
  }, [actionsData]);

  const handleAddAction = () => {
    setFormContext("Add")
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSaveAction = (formData) => {
    // Logic to handle saving the action form data
    setIsModalOpen(false);
  };

  const onRowSelect = (event) => {
    const selectedRow = event.row;
    setSelectedRowData(selectedRow);
    setIsModalOpen(true);
  };


  let rowDataForActionForm = selectedRowData;

  const api = useApi();
  
  const onSubmitForm = async (formData) => {
    setLoading(true);
    try {
      let response=null;
      console.log(formContext)
      if(formContext==="Add")
      {
        console.log("add")
        console.log("formData OnSubmit:",formData)
        response = await api.post("/incident-command/action/", null, {
          body: formData,
      })}
      else
      {
        console.log("update")
        console.log(formData)
        response = await api.post("/incident-command/actionstate/", null, {
          body: formData,        
      }
      )};
      let actionData = null
      if (response.ok) {
        if(formContext==="Add") {
          actionData = response.body.action;
        }
        else {
          actionData = response.body.actions;
        }
        console.log(response.body)
        console.log("actionData:",response.body)
        setActions((prevActions) => {
          const updatedActions = [...(prevActions || [])];
          if (formContext === "Add" && actionData.incident_service_id === formData.responderData.incident_service_id) {
            // Add the new action only to the specific responder's action list
            updatedActions.push(actionData);
          } else if (formContext === "Update") {
            // Update the existing action in the specific responder's action list
            console.log(updatedActions)
            const updatedIndex = updatedActions.findIndex(
              (action) =>
                action.action_id === actionData.action_id &&
                action.incident_service_id === actionData.incident_service_id
            );
  
            if (updatedIndex !== -1) {
              updatedActions[updatedIndex] = actionData;
            }
          }
          return updatedActions;
        });
        setIsModalOpen(false);
      } else {
        console.log(response);
        // Handle error case
      }
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle error case
    } finally {
      setLoading(false);
    }
  };

  if (!actions || actions.length === 0) {
    return (
      <div>
        <div key={responder.incident_service_id} >No actions available.</div>
        <Button variant="contained" color="primary" onClick={handleAddAction} size="xs">
          Add
        </Button>
        <Modal open={isModalOpen} onClose={handleModalClose} style={modalStyles}>
          <div>
            <ActionForm onSubmitForm={onSubmitForm} responderData={responder} authUser={authUser}/>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }} className="ActionsList">
      <StripedDataGrid
        rows={actions}
        columns={columns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        } 
        hideFooter
        rowHeight={20}
        getRowId={(row) => row.action_id}
        columnHeaderHeight={20}
        autoHeight
        onRowClick={onRowSelect}
      />
        <Modal open={isModalOpen} onClose={handleModalClose}>
          <div>
            <ActionForm onSubmitForm={onSubmitForm} responderData={responder} actionData={rowDataForActionForm} actionContext={formContext} authUser={authUser}/>
          </div>
        </Modal>
      <div>
        <Button variant="contained" color="primary" onClick={handleAddAction}>
          Add
        </Button>
      </div>
    </div>
  );
}
