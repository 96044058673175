import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Button, CircularProgress } from "@mui/material";
import IncidentForm from './IncidentForm';
import { useApi } from "../contexts/IncidentCommandApiProvider";
import { Buffer } from "buffer";
import Spinner from "react-bootstrap/Spinner";

const columns = [
  { field: "id", headerName: "ID", width: 170 },
  { field: "title", headerName: "Title", width: 500 },
  { field: "status", headerName: "Status", width: 300 }
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export default function IncidentGrid({ children, pagerdutyincidents }) {
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const api = useApi();
  const navigate = useNavigate();

  const onRowsSelectionHandler = (ids) => {
    setSelectedRowsData(
      ids.map((id) =>
        pagerdutyincidents.find((incident) => incident.id === id)
      )
    );
  };

  const onCommandClick = () => {
    setShowForm(true);
  };

  const onSubmitForm = async (formData) => {
    console.log(formData)
    setLoading(true);
    try {
      const response = await api.post("/incident-command/incidents/", null, {
        body: formData,
      });

      if (response.ok) {
        const incidentData = response.body;
        console.log(response.body)
        const incidentIdBase64 = Buffer.from(incidentData['incident_id']).toString('base64');
        navigate(`/incidentcommand/${incidentIdBase64}`);
      } else {
        console.log(response);
        // Handle error case
      }
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle error case
    } finally {
      setLoading(false);
    }
  };


  return (
    <div style={{ height: 700, width: "100%" }} className="incidentsHome">
    {loading ? (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    ) : showForm ? (
      <div>
        <IncidentForm
          selectedIncidentData={selectedRowsData}
          onSubmitForm={onSubmitForm}
        />
      </div>
    ) : (
        <div style={{ height: 700, width: "100%" }} className="IncidentGrid">
          <StripedDataGrid
            rows={pagerdutyincidents}
            columns={columns}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            checkboxSelection
            onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          />
          <div className="btnCommand" title="Create a new incident command session from selected incidents">
            <Button
              variant="contained"
              sx={{ mb: 1 }}
              onClick={onCommandClick}
              disabled={!selectedRowsData.length}
              className="btnCommand"
              title="Create a new incident command session from selected incidents"
            >
              Take Command
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}