import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Responders from './Responders';
import { useState, useEffect } from 'react';
import Timeline from './Timeline';
import { CollectionsBookmarkOutlined } from '@mui/icons-material';

export default function IncidentHeader({ headerData, actionsMap, stateMap, authUser }) {
  const [headerState, setHeaderState] = useState(headerData[0]);
  const [responderCount, setResponderCount] = useState(0);
  const [actionsCount, setActionsCount] = useState(0);
  const [actionsByState, setActionsByState] = useState({});


  useEffect(() => {
    setResponderCount(headerState.length);
    setActionsCount(getTotalActionsCount(actionsMap));
    setActionsByState(getActionsByState(actionsMap, stateMap));
  }, [headerState, actionsMap, stateMap]);

  function getTotalActionsCount(actionsMap) {
    let totalActions = 0;
    for (const serviceId in actionsMap) {
      totalActions += actionsMap[serviceId].length;
    }
    return totalActions;
  }
  function getActionsByState(actionsMap, stateMap) {
    const actionsByState = {};
  
    for (const serviceId in actionsMap) {
      actionsMap[serviceId].forEach((action) => {
        const actionId = action.action_id;
        const actionStates = stateMap[actionId] || [];
  
        // Find the highest versioned action state
        const currentState = getCurrentState(actionStates);
  
        if (!actionsByState[currentState]) {
          actionsByState[currentState] = 0;
        }
        actionsByState[currentState]++;
      });
    }
  
    return actionsByState;
  }
  
  function getCurrentState(actionStates) {
    if (!actionStates || actionStates.length === 0) {
      return 'Unknown'; // Handle if no states are available
    }
  
    if (actionStates.length === 1) {
      console.log(actionStates[0].action_version)
      return actionStates[0].status; // Return the only available state
    }
  
    console.log('Action states:', actionStates); // Debugging line
  
    // Find the highest versioned action state
    const highestVersionState = actionStates.reduce((prev, current) => {
      console.log('Comparing versions:', current.action_version, prev.action_version); // Debugging line
      return current.action_version > prev.action_version ? current : prev;
    });
  
    return highestVersionState.status;
  }

  const headerFactsHeight = -8;

  return (
    <Container fluid className="headerContainer">
      <Row className="IncidentHeader">
        <Col md={6} className="Content">
          <div className="incidentcommand_header">
              <div className="incidentcommand_facts">
                <div className="label-value">
                  <p className="incident-header-label">Incident Ref:</p>
                  <p className="incident-header-value">{headerState[0].incident_name}</p>
                </div>
                <div className="label-value">
                  <p className="incident-header-label">Incident Title:</p>
                  <p className="incident-header-value">{headerState[0].incident_title}</p>
                </div>
                <div className="label-value">
                  <p className="incident-header-label">Incident Status:</p>
                  <p className="incident-header-value">{headerState[0].incident_summary}</p>
                </div>
              </div>
              <div>
                <p className="incident-header-label">Responders:</p>
              </div>
            </div>
          <div className="responders-box">
            <Responders responders={headerState} serviceActionsMap={actionsMap} stateMap={stateMap} authUser={authUser} />
          </div>
        </Col>
        <Col md={6} className="timeline-container" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <div className="summary-section">
            <div className="label-value">
              <p className="incident-header-label">Number of Responders:</p> 
              <p className="incident-header-value">{responderCount}</p>
            </div>
            <div className="label-value">
              <p className="incident-header-label">Number of Actions: </p>
              <p className="incident-header-value">{actionsCount}</p>
            </div>
            <div className="label-value">
              <p className="incident-header-label">Actions by State:</p>
              <ul>
                {Object.entries(actionsByState).map(([state, count]) => (
                  <li key={state}>
                    {state}: {count}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <h3 style={{ color: '#34495e', marginTop: `${headerFactsHeight}px` }}>Incident Timeline</h3>
          <Timeline events={actionsMap} stateMap={stateMap} />
        </Col>
      </Row>
    </Container>
  );
}
