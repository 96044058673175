import Body from "../components/Body";
import PagerDutyIncidents from "../components/PagerDutyIncidents";

export default function HomePage() {
  return (
    <Body>
      <h1> Current PagerDuty incidents </h1>
      <p></p>
      <PagerDutyIncidents />
    </Body>
  );
}
