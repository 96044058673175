import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { useEffect, useState } from 'react';

export default function Header({ authUser }) {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (authUser) {
      setUserData(authUser);
    }
  }, [authUser]);

  return (
    <Navbar sticky="top" className="header-background">
      <Container>
        <Navbar.Brand className='brand-text'>Incident Command</Navbar.Brand>
        {userData.username && <Navbar.Text className='header-text'>Logged In As: {userData.username}</Navbar.Text>}
      </Container>
    </Navbar>
  );
}