import { useState, useRef } from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputField from "../components/InputField";
import { Buffer } from "buffer";

export default function IncidentForm({ selectedIncidentData, onSubmitForm }) {
  const [formErrors, setFormErrors] = useState({});
  const incidentRefField = useRef();
  const incidentTitleField = useRef();
  const incidentSummaryField = useRef();

  const handleSubmit = (ev) => {
    ev.preventDefault();

    const incidentRef = incidentRefField.current.value.trim();
    const incidentTitle = incidentTitleField.current.value.trim();
    const incidentSummary = incidentSummaryField.current.value.trim();

    console.log(incidentSummary)

    if (incidentRef === "") {
      setFormErrors({ incidentRef: "Cannot be blank" });
    } else {
      setFormErrors({});
      const formData = {
        incidentRef,
        incidentTitle,
        incidentSummary,
        selectedIncidentData,
      };
      onSubmitForm(formData);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputField
        name="incidentRef"
        label="Enter the internal reference for this incident"
        error={formErrors.incidentRef}
        fieldRef={incidentRefField}
      />
      <InputField
        name="incidentTitle"
        label="Enter the incident title"
        error={formErrors.incidentTitle}
        fieldRef={incidentTitleField}
      />
      <InputField
        name="incidentSummary"
        label="Describe the current known impact of the incident"
        error={formErrors.incidentSummary}
        fieldRef={incidentSummaryField}
      />
      <Button variant="primary" type="submit">
        Create Incident Session
      </Button>
    </Form>
  );
}
