import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ActionsList from "./ActionsList";
import Box from "@mui/material/Box";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function Responder({ children, serviceActions, responder, authUser }) {

  return (
    <Box key={responder.pd_user_id} sx={{ width: 230, margin: 2 }}>
      <Item>
        Name: {responder.responder_name}
        <br />
        Team: {responder.team_name}
        <br />
        Service: {responder.service_name}
        <br />
        <ActionsList key={responder.incident_service_id} actionsData={serviceActions} responder={responder} authUser={authUser} />
      </Item>
    </Box>
  );
}
