import Container from "react-bootstrap/Container";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import IncidentCommandApiProvider from "./contexts/IncidentCommandApiProvider";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import UserPage from "./pages/UserPage";
import IncidentCommandPage from "./pages/IncidentCommandPage";
import FlashProvider from "./contexts/FlashProvider";
import {Amplify} from 'aws-amplify';
import awsExports from "./aws-exports";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from "aws-amplify";
import WebsocketProvider from "./contexts/WebsocketProvider";
import LoginSuccessPage from "./pages/LoginSuccess";



const configAuth = {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    oauth: {
      domain: 'https://login.incident-command.com',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: 'https://incident-command.com/loginsuccess',
      redirectSignOut: 'https://incident-command.com/logoutsuccess',
      responseType: 'code',
    }
    
}

const authDict = {Auth: configAuth}

export default function App() {
  Amplify.configure(authDict)
  return (
    <Container fluid className="App">
      <BrowserRouter>
        <FlashProvider>
          <Authenticator>
          {({ signOut, user })  => (
            <IncidentCommandApiProvider>
              <WebsocketProvider>
                <Header authUser={user} />
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route
                    path="/incidentcommand/:icincidentid"
                    element={<IncidentCommandPage authUser={user} />}
                  />
                  <Route path="/user/:userid" element={<UserPage />} />
                  <Route path="/loginsuccess" element={<LoginSuccessPage />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </WebsocketProvider>
            </IncidentCommandApiProvider>
          )}
          </Authenticator>
        </FlashProvider>
      </BrowserRouter>
    </Container>
  );
}
