export default class WebSocketService {
    constructor(url) {
      this.socket = new WebSocket(url);
      this.socket.onopen = this.onOpen;
      this.socket.onmessage = this.onMessage;
      this.socket.onclose = this.onClose;
      this.socket.onerror = this.onError;
    }
  
    onOpen = (event) => {
      console.log('WebSocket connection established.');
      // Additional handling if needed
    };
  
    onMessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('WebSocket message received:', data);

      if (this.onMessageCallback) {
        this.onMessageCallback(data);
      }
      // Additional handling if needed
    };

    setOnMessageCallback(callback) {
        this.onMessageCallback = callback;
      }
  
    onClose = (event) => {
      console.log('WebSocket connection closed.');
      // Additional handling if needed
    };
  
    onError = (error) => {
      console.error('WebSocket error:', error);
      // Additional handling if needed
    };
  
    close = () => {
      this.socket.close();
    };
  }
  