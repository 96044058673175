import Spinner from "react-bootstrap/Spinner";
import {
  createTheme,
  experimentalStyled as styled,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Container, Stack } from "react-bootstrap";
import Responder from "./Responder";
import { useState, useEffect, useReducer } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchIcon from "@mui/icons-material/Search";
import { Auth } from 'aws-amplify';


const lightTheme = {
  body: "#FFF",
  text: "#363537",
  toggleBorder: "#FFF",
  background: "#363537",
};

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function Responders({ children, responders, serviceActionsMap, stateMap, authUser}) {
const [responderData, setResponderData] = useState(responders);
const [searchQuery, setSearchQuery] = useState("");
const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    setResponderData(responders)
  }, [responders]);

  

  const filteredResponders = searchQuery
  ? responderData.filter((responder) => {
      const searchValue = searchQuery.toLowerCase();
      return Object.values(responder).some((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchValue);
        }
        return false;
      });
    })
  : responderData;


  const renderResponders = () => {
    const currentUser = authUser;
    console.log(authUser)
    const userGroups = currentUser.signInUserSession.accessToken.payload['cognito:groups'];

    if(!Auth.currentUserInfo()){
      return(<p>Unauthorised</p>)
    }

    if (userGroups.includes('incident-commander')) {
      return (
        <>
        {responderData === undefined ? (
          <Spinner animation="border" />
        ) : (
          <>
            {responderData === null ? (
              <p>Unable to read responders data</p>
            ) : (
              <>
                {responderData.length === 0 ? (
                  <p>There are no responders assigned</p>
                ) : (
                  <Stack
                    direction="horizontal"
                    className="RespondersStack"
                    spacing={4}
                    width="100%"
                    divider={
                      <Box
                        component="hr"
                        sx={{
                          border: (theme) =>
                            `1px solid ${
                              theme.palette.mode === "dark" ? "#262B32" : "#fff"
                            }`,
                        }}
                      />
                    }
                  >
                    <Container>
                      <Row className="responders-section">
                        <Col md={8}>
                          <div className="search-box">
                            <SearchIcon style={{ marginRight: "5px" }} />
                            <input
                              type="text"
                              placeholder="Filter responders..."
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        style={{ margin: "5px" }}
                      >
                        {filteredResponders.map((responder, index) => {
                          const serviceActions =
                            serviceActionsMap[responder.incident_service_id];
                          return (
                            <Responder
                              key={index}
                              responder={responder}
                              serviceActions={serviceActions}
                              authUser={authUser}
                            />
                          );
                        })}
                      </Grid>
                    </Container>
                  </Stack>
                )}
              </>
            )}
          </>
        )}
      </>
    );
    } else if (userGroups.includes('responders')) {
      return (
        <p>Unauthorized</p>
      );
    } else {
      return <p>Unauthorized</p>;
    }
  };



  return (
    <>
      {renderResponders()}
    </>
  );
}