import { useState, useRef } from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputField from "./InputField";


export default function ActionForm({children, onSubmitForm ,responderData, actionData=null,actionContext="Add", authUser}) {
  const [formErrors, setFormErrors] = useState({});
  const actionServiceNameField = useRef();
  const actionAsigneeNameField = useRef();
  const actionSummaryField = useRef();
  const actionUpdateNotesField = useRef();
  const actionStatusSelector = useRef();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const serviceName = actionServiceNameField.current.value.trim();
    const actionAsignee = actionAsigneeNameField.current.value.trim();
    const actionSummary = actionSummaryField.current.value.trim();
    const actionCreator = authUser.attributes.email

    
    if(actionContext === "Add"){
      if (actionSummary === "") {
        setFormErrors({ actionSummary: "Cannot be blank" });
      } else {
        setFormErrors({});
        const formData = {
          serviceName,
          actionAsignee,
          actionSummary,
          responderData,
          "created_by": actionCreator
        };
        onSubmitForm(formData);
      }
    } else {
      const actionUpdateNotes = actionUpdateNotesField.current.value.trim();
      const actionStatus = actionStatusSelector.current.value.trim();
      if (actionSummary === "") {
        setFormErrors({ actionSummary: "Cannot be blank" });
      } else {
        setFormErrors({});
        const responderId = responderData['responder_id']
        const actionId = actionData['action_id']
        const incidentServiceId = responderData['incident_service_id']
        const incidentId = responderData['ic_incident_id']
        console.log(actionId)
        const formData = {
          serviceName,
          actionAsignee,
          "action_summary": actionSummary,
          "action_note": actionUpdateNotes,
          "action_status_id": actionStatus,
          "responder_id": responderId,
          "action_id": actionId,
          "incident_service_id": incidentServiceId,
          "ic_incident_id": incidentId,
          "updated_by": actionCreator,
        };
        console.log("formData:",formData)
        onSubmitForm(formData);
      }

    }
  };

  
  if(actionContext != "Add"){
    return (
      <Form onSubmit={handleSubmit} className="centered-modal">
        <InputField
          name="actionServiceName"
          label="Update Action For Service"
          error={formErrors.serviceName}
          fieldRef={actionServiceNameField}
          isDisabled={true}
          value={responderData.service_name}
        />
        <InputField
          name="actionAssigneeName"
          label="Action Assignee (determined by service)"
          error={formErrors.assigneeName}
          fieldRef={actionAsigneeNameField}
          isDisabled={true}
          value={responderData.responder_name}
        />
        <Button>Re-Assign</Button>
        <InputField
          name="actionSummary"
          label="Summary"
          error={formErrors.actionSummary}
          fieldRef={actionSummaryField}
          isDisabled={true}
          value={actionData.action_summary}
        />
        <InputField
          name="actionUpdateNotes"
          label="Add a description of the outcome of your investigation"
          error={formErrors.actionUpdateNotes}
          fieldRef={actionUpdateNotesField}
          isDisabled={false}
        />
        <Form.Select aria-label="Update action status" ref={actionStatusSelector} name='actionStatus'>
          <option>Select Action Status</option>
          <option value="2">In Progress</option>
          <option value="3">Complete</option>
        </Form.Select>
        <Button variant="primary" type="submit">
          Update Action
        </Button>
      </Form>
    );
  }

  return (
    <Form onSubmit={handleSubmit} className="centered-modal">
      <InputField
        name="actionServiceName"
        label="Create Action For Service"
        error={formErrors.serviceName}
        fieldRef={actionServiceNameField}
        isDisabled={true}
        value={responderData.service_name}
      />
      <InputField
        name="actionAssigneeName"
        label="Action Assignee (determined by service)"
        error={formErrors.assigneeName}
        fieldRef={actionAsigneeNameField}
        isDisabled={true}
        value={responderData.responder_name}
      />
      <Button>Re-Assign</Button>
      <InputField
        name="actionSummary"
        label="Describe what you would like to be checked"
        error={formErrors.actionSummary}
        fieldRef={actionSummaryField}
      />
      <Button variant="primary" type="submit">
        Create Action
      </Button>
    </Form>
  );
}
