import { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useApi } from "../contexts/IncidentCommandApiProvider";
import IncidentGrid from "./IncidentGrid";
import { Auth } from "aws-amplify";

export default function PagerDutyIncidents() {
  const [incidentData, setIncidentData] = useState([]);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthorised(true);
        const response = await api.get("/pagerduty/incidents/", null, {
          headers: { interval: 4380 },
        });
        if (response.ok) {
          setIncidentData(response.body.incidents);
        } else {
          setIncidentData([]);
        }
      } catch (error) {
        setIsAuthorised(false);
        setIncidentData([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchIncidentData();
  }, [api]);

  return (
    <>
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <>
          {isAuthorised ? (
            <>
              {incidentData.length === 0 ? (
                <p>There are no PagerDuty incidents</p>
              ) : (
                <IncidentGrid pagerdutyincidents={incidentData} />
              )}
            </>
          ) : (
            <p>You are not authorized to view this page</p>
          )}
        </>
      )}
    </>
  );
}
