import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const checkCognitoAuthentication = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (error) {
    return null;
  }
};

export default function LoginSuccessPage() {
    const history = useNavigate();

    console.log("LoginSuccessPage");
  
    useEffect(() => {
      const checkAuthentication = async () => {
        const authenticatedUser = await checkCognitoAuthentication();
        if (!authenticatedUser) {
          // Redirect to your login page route within your application
          history.push('login.incident-command.com');
        }
      };
      checkAuthentication();
    }, [history]);
  // If the user is authenticated, render the login success content
  return (
    <div>
      <h1>Welcome, you have successfully logged in!</h1>
      <p>Any additional content you want to display after login.</p>
    </div>
  );
};