import React, { createContext, useContext } from 'react';
import WebSocketService from '../services/WebSocketService';


const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_API_URL;

const WebSocketContext = createContext();


export default function WebSocketProvider({ children }){
  const socketService = new WebSocketService(WEBSOCKET_URL);

  return (
    <WebSocketContext.Provider value={socketService}>
      {children}
    </WebSocketContext.Provider>
  );
};

export function useWebSocket() {
  return useContext(WebSocketContext);
}

