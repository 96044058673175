import Body from "../components/Body";
import { Container } from "react-bootstrap";
import IncidentSession from "../components/IncidentSession";



export default function IncidentCommandPage({authUser}) {
  console.log(authUser)
  return (
    <Body sidebar={false} timeline={true}>
      <Container className="sessionContainer">
        <IncidentSession authUser={authUser}/>
      </Container>
    </Body>
  );
}
