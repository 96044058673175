import { useApi } from "../contexts/IncidentCommandApiProvider";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; 
import { Buffer } from "buffer";
import Spinner from "react-bootstrap/Spinner";
import IncidentHeader from './IncidentHeader';
import { useWebSocket } from '../contexts/WebsocketProvider';




export default function IncidentSession({ children, authUser }) {

  
  const [loading, setLoading] = useState(true);
  const [incidentSessionData, setIncidentSessionData] = useState();
  const [serviceActionsMap, setServiceActionsMap] = useState({});
  const api = useApi();
  const webSocketService = useWebSocket();
  const [incidentDataLoaded, setIncidentDataLoaded] = useState(false);
  const [actionsDataLoaded, setActionsDataLoaded] = useState(false);
  const [actionStateMap, setActionStateMap] = useState({});

  const { icincidentid } = useParams();

  var supplied_id = parseInt(Buffer.from(icincidentid,'base64').toString('ascii'))


  useEffect(() => {
    // Set the onMessage callback in the WebSocketService
    if (webSocketService) {
      webSocketService.setOnMessageCallback((data) => {
        setIncidentDataLoaded(false);
        if (data.messages[0].type === "incident_data") {
          (async () => {
            await fetchIncidentSessionData();
            setIncidentDataLoaded(true);
          })();      
        }
      });
    }
  }, [webSocketService]);



  useEffect(() => {
    (async () => {
      await fetchIncidentSessionData();
      setIncidentDataLoaded(true);
    })();
  }, [api]);

  useEffect(() => {
    if (incidentDataLoaded && incidentSessionData) {
      const serviceIds = incidentSessionData[0].map((element) => element["incident_service_id"]);
      fetchIncidentActionsAndStates(serviceIds);
    }
  }, [incidentDataLoaded, incidentSessionData]);


  const fetchIncidentSessionData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/incident-command/incidents/${supplied_id}`, null);
      if (response.ok) {
        setIncidentSessionData(response.body.incident[0]);
      } else {
        setIncidentSessionData(null);
      }
    } catch (error) {
        console.error("Error:", error);
        setIncidentSessionData(null);
    } finally {
        setLoading(false);
    }
  };

  const fetchIncidentActionsAndStates = async (incidentServices) => {
    setLoading(true);
    const actionsByService = {};
  
    try {
      // Fetch actions for incident services
      const apiCalls = incidentServices.map((incidentService) =>
        api.get(`/incident-command/actions/${incidentService}`, null)
      );
      const responses = await Promise.all(apiCalls);
  
      responses.forEach((response, index) => {
        const incidentService = incidentServices[index];
        if (response.ok) {
          if (!actionsByService[incidentService]) {
            actionsByService[incidentService] = [];
          }
          const actions = response.body.actions[0][0];
          if (Array.isArray(actions) && actions.length > 0) {
            actions.forEach((action) => {
              actionsByService[incidentService].push(action);
            });
          }
        }
      });
  
      // Update the serviceActionsMap state using a callback
      setServiceActionsMap((prevActions) => {
        return { ...prevActions, ...actionsByService };
      });
  
      // Calculate actionIds from the updated serviceActionsMap
      const actionIds = Object.values(actionsByService).flatMap(serviceActions =>
        serviceActions.map(element => element["action_id"])
      );
  
      // Fetch action state data using actionIds
      fetchIncidentActionStateData(actionIds);
    } catch (error) {
      console.error("Error:", error);
      setServiceActionsMap(null);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchIncidentActionStateData = async (incidentActions) => {
    setLoading(true);
    const actionStateByActions = {};
    try {
      const apiCalls = incidentActions.map((incidentAction) =>
        api.get(`/incident-command/actionstate/${incidentAction}`, null)
      );
      const responses = await Promise.all(apiCalls);
      responses.forEach((response, index) => {
        const incidentAction = incidentActions[index];
        if (response.ok) {
          if (!actionStateByActions[incidentAction]) {
            actionStateByActions[incidentAction] = [];
          }
          const actionStates = response.body.action_state[0][0]; 
          if (Array.isArray(actionStates) && actionStates.length > 0) {
            actionStates.forEach((actionState) => {
              actionStateByActions[incidentAction].push(actionState);
            });
          }
        } else {
          setActionStateMap(null);
        }
      });
      setActionStateMap((prevActions) => {
        console.log(actionStateByActions)
        return actionStateByActions;
      });
    } catch (error) {
      console.error("Error:", error);
      setActionStateMap(null);
    } finally {
      setLoading(false);
    }
  };

return (
  <>
    {incidentSessionData === undefined ? (
      <Spinner animation="border" />
    ) : (
      <>
        {incidentSessionData === null ? (
          <p>Unable to retrieve Incident Session data</p>
        ) : (
          <>
            {incidentSessionData.length === 0 ? (
              <p> Something went wrong!</p>
            ) : (
              <IncidentHeader headerData={incidentSessionData} actionsMap={serviceActionsMap} stateMap={actionStateMap} authUser={authUser}/>
            )}
          </>
        )}
      </>
    )}
  </>
);
}