import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/system';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#ecf0f1',
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&.header-background': {
    backgroundColor: '#34495e',
  },
}));

function getUTCTimeString(timeString){
  const actionDate = new Date(timeString);
  const hour = actionDate.getUTCHours();
  const minutes = actionDate.getUTCMinutes();
  return (1 + ((hour - 1) % 12)) + ":" + minutes.toString().padStart(2, "0") + " " + ((hour > 11) ? "PM" : "AM");
} 


function getUTCDateTimeString(timeString){
  const actionDate = new Date(timeString);
  const actionYear = actionDate.getFullYear();
  const actionMonth = actionDate.getUTCMonth();
  const actionDay = actionDate.getUTCDay();
  const actionhour = actionDate.getUTCHours();
  const actionMinutes = actionDate.getUTCMinutes();
  const actionSeconds = actionDate.getUTCSeconds();
  const dateStr = `${actionYear}-${actionMonth.toString().padStart(2, "0")}-${actionDay.toString().padStart(2, "0")} ${actionhour.toString().padStart(2, "0")}:${actionMinutes.toString().padStart(2, "0")}:${actionSeconds.toString().padStart(2, "0")}`
  return (dateStr);
}  




export default function Timeline({ events, stateMap, responders }) {
  const [expandedRow, setExpandedRow] = useState(null);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: 'white',
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&.header-background': {
      backgroundColor: '#34495e',
    },
  }));

  const rows = [];
  for (const key in events) {
    for (const subKey in events[key]) {
      rows.push(events[key][subKey]);
    }
  }

  rows.sort((a, b) => new Date(a.action_created_date) - new Date(b.action_created_date));


  for(const key in stateMap) {
    stateMap[key].sort((b, a) => new Date(a.status_date) - new Date(b.status_date));
  }

  const handleRowClick = (actionId) => {
    if (expandedRow === actionId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(actionId);
    }
  };

  return (
    <div className="Time" style={{ width: '100%', height: '100%' }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow className="header-background">
              <StyledTableCell style={{width: '10%'}}>Timestamp</StyledTableCell>
              <StyledTableCell style={{width: '50%'}}>Action Summary</StyledTableCell>
              <StyledTableCell style={{width: '20%'}}>Asignee</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((event) => (
              <React.Fragment key={event.action_id}>
                <TableRow onClick={() => handleRowClick(event.action_id)}>
                  <TableCell>
                    {getUTCTimeString(event.action_created_date)}
                    {expandedRow === event.action_id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </TableCell>
                  <TableCell>
                    {event.action_summary}
                  </TableCell>
                  <TableCell>
                    {event.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell colSpan={3} padding='none' style={{ borderCollapse: 'collapse' }}>
                  {stateMap[event.action_id] ? ( 
                    <Collapse in={expandedRow === event.action_id}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Notes</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Timestamp</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stateMap[event.action_id].map((state, index) => (
                            <React.Fragment key={index}>
                              <TableRow>
                                <TableCell>{state.state_notes}</TableCell>
                                <TableCell>{state.status}</TableCell>
                                <TableCell>{getUTCDateTimeString(state.status_date)}</TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  ) : null} 
                </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}